// Attach to remote forms and specify a target container's ID to put the returned HTML in
export default (targetElementId, eventListenerOnDocument = false) => ({
  form: null,

  init() {
    this.form = this.$root

    const targetElement = document.getElementById(targetElementId)
    const listenerRoot = eventListenerOnDocument ? document : this.form
    listenerRoot.addEventListener('ajax:success', ({ detail }) => (targetElement.innerHTML = detail[2].responseText))
    listenerRoot.addEventListener('ajax:error', () => alert(I18n.unknown_error))
  },

  submit() {
    this.form.requestSubmit()
  },

  // Use x-bind="searchField" on form inputs to make them trigger a form submit on input after a small delay
  searchField: {
    ['@input.debounce']() {
      this.submit()
    },
  },

  // Similar to searchField, but for elements such as select boxes and flatpickrs that trigger a change instead of an input
  searchBox: {
    ['@change']() {
      this.submit()
    },
  },
})
