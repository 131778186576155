import type { AlpineComponent } from 'alpinejs'

// Make an ajax request to the specified url after a confirmation and reload the page if the request was successful
// Requires data-title and data-text on the element for the confirmation dialog
// An optional data-warning parameter adds an additional check to the confirmation dialog
type HttpMethod = 'GET' | 'POST' | 'PUT' | 'DELETE' | 'PATCH'
export default (
  method: HttpMethod,
  url: string | null = null,
  reload = true,
  extraCallback?: () => void
): AlpineComponent<unknown> => ({
  init(): void {
    const button = this.$root
    const { title, text, warning } = button.dataset

    if (!title || !text) {
      console.error('Please provide a title and text')
      return
    }

    const headers = new Headers({ 'Content-Type': 'application/json' })

    if (button instanceof HTMLAnchorElement) {
      url ||= button.href
    }

    const callback = async () => {
      if (!url) {
        console.error('No URL provided')
        return
      }

      try {
        const res = await fetch(url, { method, headers })
        if (!res.ok) throw new Error()

        if (reload) {
          location.reload()
          return
        }

        // Show a flash message from the server for successful operations and remove table row if present
        if (res.status !== 204) {
          const { msg } = (await res.json()) as { msg?: string }
          msg && window.flash('notice', msg)
        }

        button.closest('tr')?.remove()

        // Possible additional steps can be provided in `extraCallback`
        extraCallback && extraCallback()
      } catch {
        alert(window.I18n.unknown_error)
      }
    }

    button.addEventListener('click', async (e) => {
      e.preventDefault()
      await window.confirmationModal(title, text, callback, warning)
    })
  },
})
