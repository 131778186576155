import type { AlpineComponent } from 'alpinejs'

type HomepageComponent = {
  scrolled: boolean
  tab: string
  changeTab: (newTab: string) => void
}

export const homepage = (): AlpineComponent<HomepageComponent> => ({
  scrolled: false,
  tab: 'school',

  init() {
    // Reset scroll position to the top of the page
    setTimeout(() => window.scrollTo(0, 0), 100)

    document.addEventListener('scroll', () => {
      this.scrolled = window.scrollY !== 0
    })
  },

  changeTab(newTab) {
    this.tab = newTab

    const tabButtons = this.$refs.tabButtons
    const header = this.$refs.header
    if (!tabButtons || !header) return

    const targetTop = tabButtons.getBoundingClientRect().top
    let headerHeight = header.offsetHeight

    // If scrollY is at 0, the dark header is visible, but it will switch to the white one (32px shorter) when scrolling starts
    if (window.scrollY === 0) {
      headerHeight -= 32
    }

    window.scrollTo({
      top: targetTop + window.scrollY - headerHeight,
      behavior: 'smooth',
    })
  },
})

type PasswordResetComponent = {
  password: string
  passwordConfirmation: string
  passwordTooShort: boolean
  infoColorClass: 'text-gray-500' | 'text-red-600' | 'text-green-600'
  validConfirmation: boolean
  checkConfirmation: () => void
}

export const passwordReset = (passwordConfirmationError: string): AlpineComponent<PasswordResetComponent> => ({
  password: '',
  passwordConfirmation: '',

  init() {
    this.$watch('password', () => this.checkConfirmation())
    this.$watch('passwordConfirmation', () => this.checkConfirmation())
  },

  get passwordTooShort() {
    return this.password.length < 8
  },

  get infoColorClass() {
    if (this.password.length === 0) {
      return 'text-gray-500'
    } else if (this.passwordTooShort) {
      return 'text-red-600'
    } else {
      return 'text-green-600'
    }
  },

  get validConfirmation() {
    return !this.passwordConfirmation.length || this.password === this.passwordConfirmation
  },

  checkConfirmation() {
    const confirmationField = this.$refs.confirmationField as HTMLInputElement | undefined
    confirmationField?.setCustomValidity(this.validConfirmation ? '' : passwordConfirmationError)
  },
})
