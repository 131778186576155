export const getCourseTest = (testUrl: string, openAutomatically = false) => ({
  testAvailable: openAutomatically,

  async init() {
    openAutomatically ? (await this.openTest()) : setTimeout(() => this.checkVideoProgress(), 200)
    document.addEventListener('checkVideoProgress', () => this.checkVideoProgress())
  },

  async openTest() {
    const testContainer = document.querySelector('#test-container')
    if (!testContainer) return

    window.loadingSpinner(true)

    try {
      const res = await fetch(testUrl)

      if (res.ok) {
        const html = await res.text()
        testContainer.innerHTML = html

        if (!openAutomatically) {
          // setTimeout() is needed to make sure the test has fully rendered, otherwise it doesn't scroll all the way
          setTimeout(() => testContainer.scrollIntoView({ behavior: 'smooth', block: 'start' }), 0)
        }
      } else {
        const message = await res.text()
        window.flash('alert', message)
      }
    } catch (error) {
      console.error(error)
      alert(window.I18n.unknown_error)
    }

    window.loadingSpinner(false)
  },

  checkVideoProgress() {
    const progressBars = [...document.querySelectorAll('.video-progress')] as HTMLProgressElement[]
    if (this.testAvailable || progressBars.some(({ value }) => value < 98)) return

    this.testAvailable = true
  },
})

export const courseTest = (subjectId: number, questionIds: number[]) => ({
  questionIds,
  currentQuestion: 1,
  answers: [] as number[],
  done: false,

  async next() {
    this.currentQuestion === this.questionIds.length ? await this.submit() : this.currentQuestion++
  },

  async submit() {
    window.loadingSpinner(true)

    const testAnswers = {
      user_test_score_result: {
        in_app_purchase_subject_id: subjectId,
        test_score_answer_ids: this.answers,
        question_order: this.questionIds,
      },
    }

    const body = JSON.stringify(testAnswers)
    const headers = { 'Content-Type': 'application/json' }

    try {
      const res = await fetch('/student/save_course_test', { method: 'POST', body, headers })
      if (!res.ok) throw new Error()

      const html = await res.text()
      document.body.insertAdjacentHTML('beforeend', html)
    } catch (error) {
      console.error(error)
      alert(window.I18n.unknown_error)
    }

    window.loadingSpinner(false)
    this.done = true
  },
})
