import type { AlpineComponent } from 'alpinejs'
import { validFormError } from '~/utils/form_errors'

type UJSResponse = CustomEvent<[Document | string | string[] | { error: string }, string, XMLHttpRequest]>

// Simple component for basic modal (remote) forms that don't need any fancy submit logic
// To make the form submissions remote, use "form_with" and "local: false" OR "form_for" and "remote: true"
// Successful form submission reloads the page (unless otherwise specified), errors get rendered at the top of the form without reloading
export default (reload = true, followRedirectPath = false): AlpineComponent<unknown> => ({
  init() {
    const form = this.$root

    form.addEventListener('ajax:success', (event) => {
      if (!reload) return

      // By default, the page is refreshed, but if the server responds with a URL (such as the URL of a newly created resource), that can also be followed
      const { detail } = event as UJSResponse
      const redirectPath = detail[0]

      followRedirectPath && typeof redirectPath === 'string'
        ? window.location.assign(redirectPath)
        : window.location.reload()
    })

    form.addEventListener('ajax:error', (event) => {
      const { detail } = event as UJSResponse
      let data = detail[0]

      // Devise sends JSON error responses as { error: string }
      const isErrorObject = (error: Document | string | string[] | { error: string }): error is { error: string } =>
        typeof error === 'object' && Object.keys(error).includes('error')

      if (isErrorObject(data)) {
        data = [data.error]
      }

      const errors = validFormError(data) ? data : null
      window.renderFormErrors(errors, form)
    })
  },
})
