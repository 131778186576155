export default (url, selectedLicenseIds, useDefaultFees, regulatoryFees) => ({
  products: { own: [], external: [] },
  selectedLicenseIds,
  useDefaultFees,
  regulatoryFees,
  includesRegulatoryFees: useDefaultFees || regulatoryFees.some((fee) => fee.selected),
  currencyFormatter: new Intl.NumberFormat(window.lang, { style: 'currency', currency: 'EUR' }),

  // Fetch available products from the backend
  async init() {
    this.products = await (await fetch(url)).json()
  },

  get availableProducts() {
    const sortFn = (a, b) => (a.name < b.name ? -1 : 1)
    const own = this.products.own.sort(sortFn).filter((p) => !p.selected)
    const external = this.products.external.sort(sortFn).filter((p) => !p.selected)
    return { own, external }
  },

  get selectedProducts() {
    const own = this.orderedProducts.own.filter((p) => p.selected)
    const external = this.orderedProducts.external.filter((p) => p.selected)
    return { own, external }
  },

  get orderedProducts() {
    const own = this.products.own.sort((a, b) => a.position - b.position)
    const external = this.products.external.sort((a, b) => a.position - b.position)
    return { own, external }
  },

  get packageTotalPrice() {
    return this.selectedProducts.own.reduce(
      (memo, product) => {
        memo.discounted += this.totalPerUnit(product)
        memo.normal += Math.round(+product.basePrice * 100) * product.quantity
        return memo
      },
      { discounted: 0, normal: 0 }
    )
  },

  get availableRegulatoryFees() {
    if (!this.includesRegulatoryFees) return []

    return this.regulatoryFees.filter(
      (fee) => fee.license_ids.length === 0 || this.selectedLicenseIds.every((id) => fee.license_ids.includes(id))
    )
  },

  get availableRegulatoryFeesByCategory() {
    return this.availableRegulatoryFees.reduce((memo, fee) => {
      memo[fee.category_name] ||= []
      memo[fee.category_name].push(fee)
      return memo
    }, {})
  },

  toggleUseDefaultFees() {
    this.useDefaultFees = !this.useDefaultFees

    this.regulatoryFees.forEach((fee) => {
      if (fee.select_by_default) fee.selected = this.useDefaultFees
    })
  },

  add(product) {
    product.position = this.selectedProducts[product.type].length + 1
    product.selected = true
  },

  remove(product) {
    // Decrement products with a higher position to keep the ordering sequential
    const nextProducts = this.selectedProducts[product.type].filter((p) => p.position > product.position)
    nextProducts.forEach((p) => p.position--)

    product.position = null
    product.selected = false
  },

  // Move the product closer to the beginning of the list
  decrementPosition(product) {
    if (product.position === 1) return

    const previousProduct = this.selectedProducts[product.type].find((item) => item.position === product.position - 1)
    previousProduct.position++
    product.position--
  },

  // Move the product towards the end of the list
  incrementPosition(product) {
    const collection = this.selectedProducts[product.type]
    if (product.position === collection.length) return

    collection.find((item) => item.position === product.position + 1).position--
    product.position++
  },

  // Handle currency as cents to prevent issues with floating point imprecision
  centsPerUnit(product) {
    const { basePrice, discountType, discountPercentage, discountPerUnit } = product

    // Use rounding instead of parseInt or something similar to tackle float imprecision (such as 16.90 * 100)
    const basePriceCents = Math.round(+basePrice * 100)
    const discountCents = Math.round(+discountPerUnit * 100)

    switch (discountType) {
      case 'amount':
        return basePriceCents - discountCents
      case 'percentage':
        const percentageMultiplier = 1 - discountPercentage / 100.0
        return Math.round(basePriceCents * percentageMultiplier)
      default:
        return basePriceCents
    }
  },

  eurosFromCents(cents) {
    return this.currencyFormatter.format(cents / 100.0)
  },

  totalPerUnit(product) {
    return this.centsPerUnit(product) * product.quantity
  },

  discount({ discountType, discountPerUnit, discountPercentage }) {
    if (discountType === 'none') return null

    return discountType === 'percentage' ? discountPercentage : discountPerUnit
  },

  buildFormData() {
    const modifiedProducts = Object.values(this.products)
      .flat()
      .filter((p) => p.selected || p.bundledProductId)

    const bundledProductsData = modifiedProducts.reduce((memo, product, index) => {
      const { bundledProductId, productId, quantity, selected, discountType, position } = product

      // Products with a bundledProductId (existing bundled products) that are not selected should be removed
      const bundledProduct = {
        id: bundledProductId,
        product_id: productId,
        discount_type: discountType,
        discount: this.discount(product),
        quantity,
        position,
        _destroy: !selected,
      }

      return { ...memo, [index]: bundledProduct }
    }, {})

    // Remove default fees if useDefaultFees is checked. They are only shown as checked on the UI for a visual cue, but they shouldn't be saved to the db.
    const selectedRegulatoryFeeIds = this.includesRegulatoryFees
      ? this.availableRegulatoryFees
          .filter((f) => f.selected && !(this.useDefaultFees && f.select_by_default))
          .map((f) => f.id)
      : []

    return {
      product_package: {
        name: this.$refs.packageName.value,
        description: this.$refs.description.value,
        driving_license_class_ids: this.selectedLicenseIds,
        use_default_regulatory_fees: this.includesRegulatoryFees && this.useDefaultFees,
        regulatory_fee_ids: selectedRegulatoryFeeIds,
        bundled_products_attributes: bundledProductsData,
      },
    }
  },

  async submit() {
    const form = this.$root
    const method = form.querySelector('[name="_method"]')?.value || 'POST'
    const headers = { 'Content-Type': 'application/json' }
    const body = JSON.stringify(this.buildFormData())
    const options = { method: method.toUpperCase(), redirect: 'manual', headers, body }

    try {
      const response = await fetch(form.action, options)

      if (response.ok) {
        const redirectUrl = await response.text()
        window.location = redirectUrl
        return
      }

      const errorHTML = await response.text()

      document.querySelector('.form-errors')?.remove()
      form.insertAdjacentHTML('afterbegin', errorHTML)
      form.scrollIntoView({ behavior: 'smooth' })
    } catch (error) {
      console.error(error)
    }
  },

  DiscountButton: {
    type: 'button',

    ['@click']() {
      this.product.discountType = this.$el.dataset.buttonType
    },

    [':class']() {
      const baseClasses = 'material-symbols-outlined icon-sm p-2'
      const buttonType = this.$el.dataset.buttonType
      const checkedClass = buttonType === 'none' ? 'red-btn' : 'green-btn'
      const buttonClass = this.product.discountType === buttonType ? checkedClass : 'white-btn'
      return `${baseClasses} ${buttonClass}`
    },
  },
})
