export default (supervisionEndTime: number, minutesLeftTranslation: string, endMessage: string) => ({
  remainingSeconds: null as unknown as number,
  supervisionEndTime: new Date(supervisionEndTime),

  init() {
    this.updateRemainingSeconds()

    const countdownInterval = setInterval(async () => {
      this.updateRemainingSeconds()

      if (this.remainingSeconds === 300) {
        // Alert the user about low time
        document.fullscreenElement && (await document.exitFullscreen())
        window.flash('warning', `${this.remainingSeconds / 60} ${minutesLeftTranslation}`)
      } else if (this.remainingSeconds <= 0) {
        clearInterval(countdownInterval)
        document.fullscreenElement && (await document.exitFullscreen())
        this.endLesson()
      }
    }, 1000)
  },

  get remainingTimeString() {
    const hours = (this.remainingSeconds % (60 * 60 * 24)) / (60 * 60)
    const minutes = (this.remainingSeconds % (60 * 60)) / 60
    const seconds = this.remainingSeconds % 60
    const padded = (num: number) => String(Math.floor(num)).padStart(2, '0')

    return `${padded(hours)}:${padded(minutes)}:${padded(seconds)}`
  },

  // Pause the video and kick the user out after dismissing an alert
  endLesson() {
    window.dispatchEvent(new CustomEvent('pauseCourseVideo'))

    // The alert blocks the last UI update for the counter (1 to 0)
    // Moving it to the end of the event loop with something like setTimeout() helps
    setTimeout(() => {
      alert(endMessage)
      location.assign('/')
    }, 0)
  },

  updateRemainingSeconds() {
    const remainingMs = +this.supervisionEndTime - +new Date()
    this.remainingSeconds = Math.max(0, Math.round(remainingMs / 1000))
  },
})
