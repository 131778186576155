export default (invoiceId) => ({
  invoiceType: 'original',

  async saveChanges() {
    const url = this.$el.href

    // In the invoice edit view the whole page can be reloaded to show the changes
    // In billing/index only reload the relevant row
    const reload = location.pathname.split('/').at(-1) === 'edit'
    const dialog = this.$root.closest('dialog')

    try {
      const res = await fetch(url, { method: 'POST' })
      const msg = await res.text()

      if (!res.ok) {
        window.renderFormErrors(msg, dialog.querySelector('.modal-contents'))
        return
      }

      if (reload) {
        window.location.reload()
        return
      }

      window.flash('notice', msg)
      this.renderRow()
      dialog.remove()
    } catch {
      alert(window.I18n.unknown_error)
    }
  },

  async renderRow() {
    const originalRow = document.querySelector(`#invoice-row-${invoiceId}`)
    if (!originalRow) return

    const rowType = originalRow.dataset.rowType
    const res = await fetch(`/teacher/invoices/${invoiceId}/invoice_row?row_type=${rowType}`)
    originalRow.outerHTML = await res.text()
  },
})
